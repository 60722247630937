import { LocaleMessages } from 'vue-i18n';

const locales: LocaleMessages = {
  'pt-br': {
    usersAndContacts: {
      title: 'Cadastre e gerencie usuários e contatos para acesso aos produtos',
      tabs: {
        userTitle: 'Usuários',
        contactTitle: 'Contatos',
        profilesTitle: 'Perfis',
      },
    },
  },
  en: {
    usersAndContacts: {
      title: 'Register and manage users and contacts to access products',
      tabs: {
        userTitle: 'Users',
        contactTitle: 'Contacts',
        profilesTitle: 'Profiles',
      },
    },
  },
  es: {
    usersAndContacts: {
      title: 'Registre y administre usuarios y contactos para acceder a los productos.',
      tabs: {
        userTitle: 'Usuarios',
        contactTitle: 'Contactos',
        profilesTitle: 'Perfiles',
      },
    },
  },
};

export default locales;
