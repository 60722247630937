
import Vue from 'vue';
import PageCard from '@/common/template/PageCard.vue';
import locales from '@/resources/users-and-contacts/locales';
import CompanyService from '@/common/services/CompanyService';

export default Vue.extend({
  name: 'UsersAndContacts',
  i18n: {
    messages: locales,
  },
  components: {
    PageCard,
  },

  data: (vm?: Vue | any) => ({
    items: [
      {
        label: vm.$t('usersAndContacts.tabs.profilesTitle'),
        to: {
          name: 'users-and-contacts:profiles',
        },
      },
      {
        label: vm.$t('usersAndContacts.tabs.userTitle'),
        to: {
          name: 'users-and-contacts:users',
        },
      },
    ],
    hasBpoProduct: false,
  }),

  async mounted() {
    this.masterInformation();
  },

  methods: {
    async masterInformation(): Promise<void> {
      const result = await CompanyService.masterInformation();
      this.hasBpoProduct = result.hasBpoProduct;
    },
  },
});
