var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "PageCard",
    { staticClass: "users-and-contacts-template" },
    [
      _c("template", { slot: "header" }, [
        _c("span", { staticClass: "p-d-block pt-3 px-2 fs14 grey300" }, [
          _vm._v(" " + _vm._s(_vm.$t("usersAndContacts.title")) + " "),
        ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "div",
          [_c("TabMenu", { attrs: { model: _vm.items } }), _c("router-view")],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }